import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

export default function CustomIconButton({placement, icon, style, tooltipContent, ...props}) {
    if(!!tooltipContent) return (
       <Tooltip title={tooltipContent} {...props} placement={placement}>
            <IconButton style={style}>
                {icon}
            </IconButton>
       </Tooltip>
    )
    else return (
        <IconButton style={style} {...props}>
            {icon}
        </IconButton>
    )
}
