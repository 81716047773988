import { makeStyles } from "@mui/styles";

let planStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    display: "flex",
    width: "100%",
    height: "inherit",
  },
  contentHead: {
    "& h3": {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
  },
  contentMain: {
    marginTop: 20,
  },
  actionBtn: {
    marginTop: 30,
  },

  paper: {
    padding: 20,
    margin: "10px 0px",
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
  },
  paper2: {
    border: `2px solid ${theme.palette.primary.main}`,
    position: "relative",
    "& .icon": {
      position: "absolute",
      top: 20,
      right: 20,
    },
  },
  planContainer: {
    marginBottom: 30,
    display: "flex",
    gap: 10,
    cursor: "pointer",
    "& .subText": {
      color: theme.palette.grey[500],
    },
  },
  icon: {
    height: 24,
    width: 24,
  },
  divider: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "60px auto",
  },
  emptyView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingBottom: "3vh",
  },
  contentSection: {
    height: "fit-content",
    [theme.breakpoints.up("xs")]: {
      padding: "40px 20px",
      width: "100%",
      overflow: "auto",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "80px 40px",
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
    },
  },
  stack: {
    width: "-webkit-fill-available",
  },
  cardTitle: {
    padding: "inherit",
    fontWeight: "bold",
  },
  cardSubTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      padding: "5px 20px",
    },
  },
  cardContainer: {
    textAlign: "center",
    display: "flex",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      maxWidth: 320,
      margin: "0px auto",
      padding: "0px 5px",
    },
    overflow: "auto",
    gap: 20,
    height: "inherit",
  },
  bulletContainer: {
    fontSize: "0.85rem",
    color: theme.palette.grey[600],
    paddingLeft: 10,
    paddingBottom: 20,
    "& p": {
      padding: 4,
      margin: 0,
      display: "grid",
      gap: 5,
      alignItems: "center",
      justifyContent: "start",
      justifyItems: "center",
      gridAutoFlow: "column",
    },
  },
  checkIcon: {
    color: theme.palette.success.dark,
    textSizeAdjust: "none",
  },
  rejectIcon: {
    color: theme.palette.error.dark,
  },
  priceHead: {
    fontSize: "1.2rem",
    display: "flex",
    flexDirection: "column",
    "& text": {
      fontSize: "0.8rem",
    },
  },
  priceTag: {
    fontSize: "1.3rem",
    margin: "20px 0px",
    width: "100%",
    textAlign: "center",
    fontWeight: "bolder",
    "& span": {
      fontSize: "1rem",
      color: theme.palette.grey[500],
    },
  },
  priceDesc: {
    marginBottom: 10,
  },
  priceContent: {
    color: theme.palette.grey[500],
  },

  highlightText: {
    fontSize: "larger",
    fontWeight: "bold",
    color: "#000",
  },
  freeText: {
    color: theme.palette.success.darker,
    fontWeight: "bolder",
  },
  paidText: {
    color: theme.palette.warning.dark,
    fontWeight: "bolder",
  },
  offerText: {
    color: theme.palette.error.dark,
    background: theme.palette.error.lighter,
    textDecoration: "line-through",
    fontSize: "0.9rem",
    padding: "3px",
    borderRadius: theme.shape.borderRadiusSm,
    marginRight: 10,
  },
}));

export default planStyles;
