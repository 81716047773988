import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import { GoSettings } from "react-icons/go";
import CustomButton from "../../components/CustomButton/Index";
import CustomNotification from "../../components/CustomNotification/Index";
import CustomSlider from "../../components/CustomSlider";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import scoringStyles from "../../styles/pages/Scoring/index.style";
import scoringWeightages from "./weightages";

export default function ScoringCriteriaPage() {
  const classes = scoringStyles();
  const [scoringState, setScoringState] = React.useState({
    impact: 0,
    skills: 0,
    achievement: 0,
    internship: 0,
    award: 0,
  });
  const [scoringData, setScoringData] = React.useState([]);
  const token = localStorage.getItem("token");
  const [notify, setNotify] = React.useState(false);

  React.useEffect(() => {
    axios.post(endPoints.getScoringDetails, { token }).then((r) => {
      setScoringData(r.data.results);
      if (r.data.results.length > 0) {
        setScoringState({
          impact: r.data.results[0].scoring_params.find(
            (sc) => sc.sci_param === "wtg_impact"
          ).sci_val,
          skills: r.data.results[0].scoring_params.find(
            (sc) => sc.sci_param === "wtg_skills"
          ).sci_val,
          achievement: r.data.results[0].scoring_params.find(
            (sc) => sc.sci_param === "wtg_academic"
          ).sci_val,
          internship: r.data.results[0].scoring_params.find(
            (sc) => sc.sci_param === "wtg_experience"
          ).sci_val,
          award: r.data.results[0].scoring_params.find(
            (sc) => sc.sci_param === "wtg_inititaives"
          ).sci_val,
        });
      }
    });
  }, []);
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <GoSettings /> Scoring Criteria
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomNotification
            open={!!notify}
            alertMessage={notify}
            severity={"success"}
            variant="filled"
            onClose={() => setNotify(false)}
          />
          <Grid container spacing={3}>
            <Grid item xs={8} className={classes.instituteInfoContainer}>
              <Paper className={classes.paper}>
                <div className="row">
                  <div className="col35">Impact</div>
                  <div className="col65">
                    <CustomSlider
                      onChange={(v) => {
                        Object.values({
                          ...scoringState,
                          impact: v,
                        }).reduce((a, b) => a + b, 0) <= 100 &&
                          setScoringState({
                            ...scoringState,
                            impact: v,
                          });
                      }}
                      value={scoringState.impact}
                      min={0}
                      max={100}
                      stepCount={5}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col35">Skills</div>
                  <div className="col65">
                    <CustomSlider
                      onChange={(v) => {
                        Object.values({
                          ...scoringState,
                          skills: v,
                        }).reduce((a, b) => a + b, 0) <= 100 &&
                          setScoringState({
                            ...scoringState,
                            skills: v,
                          });
                      }}
                      value={scoringState.skills}
                      min={0}
                      max={100}
                      stepCount={5}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col35">Academics</div>
                  <div className="col65">
                    <CustomSlider
                      onChange={(v) => {
                        Object.values({
                          ...scoringState,
                          achievement: v,
                        }).reduce((a, b) => a + b, 0) <= 100 &&
                          setScoringState({
                            ...scoringState,
                            achievement: v,
                          });
                      }}
                      value={scoringState.achievement}
                      min={0}
                      max={100}
                      stepCount={5}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col35">Experience</div>
                  <div className="col65">
                    <CustomSlider
                      onChange={(v) => {
                        Object.values({
                          ...scoringState,
                          internship: v,
                        }).reduce((a, b) => a + b, 0) <= 100 &&
                          setScoringState({
                            ...scoringState,
                            internship: v,
                          });
                      }}
                      value={scoringState.internship}
                      min={0}
                      max={100}
                      stepCount={5}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col35">Initiatives</div>
                  <div className="col65">
                    <CustomSlider
                      onChange={(v) => {
                        Object.values({
                          ...scoringState,
                          award: v,
                        }).reduce((a, b) => a + b, 0) <= 100 &&
                          setScoringState({
                            ...scoringState,
                            award: v,
                          });
                      }}
                      value={scoringState.award}
                      min={0}
                      max={100}
                      stepCount={5}
                    />
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <Typography variant="h4">Last Modified </Typography>
                <Typography variant="body1">
                  {scoringData[0] ? scoringData[0].updated_at : "--"}
                </Typography>
                {scoringData.length > 0 ? (
                  <CustomButton
                    text="Modify Weightages"
                    variant="contained"
                    color="warning"
                    fullWidth
                    disabled={
                      Object.values(scoringState).reduce((a, b) => a + b, 0) !==
                      100
                    }
                    className={classes.actionBtn}
                    onClick={(e) => {
                      axios
                        .patch(endPoints.updateScoringDetails, {
                          token,
                          updated_at: "now",
                          dsw_id: scoringData[0].dsw_id,
                          scoring_params: JSON.stringify(
                            scoringWeightages(scoringState)
                          ),
                        })
                        .then((r) =>
                          setNotify("Scoring Data Updated Successfully")
                        );
                    }}
                  />
                ) : (
                  <CustomButton
                    text="Save Weightages"
                    variant="contained"
                    fullWidth
                    disabled={
                      Object.values(scoringState).reduce((a, b) => a + b, 0) !==
                      100
                    }
                    className={classes.actionBtn}
                    onClick={(e) => {
                      axios
                        .post(endPoints.addScoringDetails, {
                          token,
                          role: "Institute",
                          category: "default",
                          scoring_params: JSON.stringify(
                            scoringWeightages(scoringState)
                          ),
                        })
                        .then((r) => {
                          setScoringData(r.data.results);
                          setNotify("Scoring Data Saved Successfully");
                        });
                    }}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
