import { makeStyles } from "@mui/styles";

const customInputStyles = makeStyles((theme) => ({
  inputLabelNoShrink: {
    textTransform: "uppercase",
    transform: "translate(2px, -30px) scale(1)",
    color: theme.palette.text.secondary,
  },
  root: {
    marginTop: "20px",
    "& .MuiInputBase-root": {
      color: theme.palette.text.secondary,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.text.secondary,
    },
  },
  notchedOutline: {
    borderWidth: "0px",
    boxShadow: "1px 3px 10px rgba(0, 0, 0, 0.17)",
    borderColor: "white",
  },
}));

export default customInputStyles;
