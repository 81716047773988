import React from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import { CircularProgress, Paper, Typography } from "@mui/material";
import axios from "axios";
import endPoints from "../../config/endPoint";
import ForgetPassComponent from "./forgetPass";

export default function LoginForm() {
  const navigate = useNavigate();
  const classes = layoutStyles();
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [msg, setMessage] = React.useState("");
  const { email, token } = useParams();
  if (loading) {
    return (
      <Paper className={classes.layOutPaper}>
        <CircularProgress
          disableShrink
          size={30}
          color="warning"
          thickness={6}
          style={{
            marginRight: 10,
          }}
        />
      </Paper>
    );
  }
  return email && token ? (
    <ForgetPassComponent />
  ) : (
    <Paper className={classes.layOutPaper}>
      {error && (
        <Typography variant="body1" fontWeight={"bold"} color="error" mb={3}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputField
            label="email"
            fullWidth
            value={state.email}
            error={
              state.email &&
              !state.email.match(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
              )
            }
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                email: e.target.value,
              });
            }}
            helperText="Enter your valid email address"
          />
        </Grid>
        <Grid item xs={12} position="relative" mb={3}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                password: e.target.value,
              });
            }}
          />
          {msg ? (
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                right: 0,
                mt: 1,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {msg}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                right: 0,
                mt: 1,
                cursor: "pointer",
                textDecoration: "underline",
                color: (theme) => theme.palette.primary.main,
              }}
              onClick={() => {
                setLoading(true);
                if (state.email) {
                  axios
                    .post(endPoints.forgetPassword, {
                      email: state.email,
                    })
                    .then((r) => {
                      setLoading(false);
                      setMessage("A password reset email is sent.");
                    })
                    .catch((e) => {
                      setLoading(false);
                      alert(e.response.data.error);
                    });
                } else {
                  alert("Provide email ID to reset your password.");
                }
              }}
            >
              Forget Password ?
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            text={"Sign In"}
            fullWidth
            variant="contained"
            onClick={(e) => {
              if (
                state.email.match(
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                ) &&
                state.password
              ) {
                axios
                  .post(endPoints.signIn, {
                    email: state.email,
                    password: state.password,
                  })
                  .then((r) => {
                    console.log(r.data.results);
                    localStorage.setItem("token", r.data.results.token);
                    navigate("/dashboard");
                  })
                  .catch((e) => setError("Email or Password is incorrect!"));
              } else {
                setError("Invalid Credentials Provided!");
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
