import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaMailBulk, FaPen, FaPlus, FaTrash, FaUserPlus } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomIconButton from "../../components/CustomIcon/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomModal from "../../components/CustomModal/Index";
import CustomNotification from "../../components/CustomNotification/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import studentAllotmentStyles from "../../styles/pages/StudentAllotment/index.style";

export default function StudentAllotmentPage() {
  const classes = studentAllotmentStyles();
  const [programList, setProgramList] = React.useState([]);
  const token = localStorage.getItem("token");
  const [program, setProgram] = React.useState({});
  const [isAllot, setAllot] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [studentList, setStudentList] = React.useState([]);
  const [studentCount, setStudentCount] = React.useState(null);
  const [isMailSent, setMailSent] = React.useState(false);
  const [mailMsg, setMailMsg] = React.useState("");
  const [allotState, setAllotState] = React.useState({
    plan_id: "",
    program_details_id: "",
    allotName: "",
    allotEmail: "",
    allotPhone: null,
    allotStatus: "allotted",
    isModify: false,
    license_purchased: 0,
  });

  const getAllotedStudentListAPI = (program_details_id = undefined) => {
    setLoading(true);
    setAllot(false);
    axios
      .post(endPoints.getAllotedStudents, { token, program_details_id })
      .then((r) => {
        setLoading(false);
        setStudentList(
          r.data.results?.sort((a, b) => {
            if (a.allot_status?.toLowerCase() > b.allot_status?.toLowerCase()) {
              return 1;
            } else if (
              a.allot_status?.toLowerCase() < b.allot_status?.toLowerCase()
            ) {
              return -1;
            } else {
              // the characters are equal.
              return 0;
            }
          })
        );
      })
      .catch((e) => setLoading(false));
  };

  React.useEffect(() => {
    axios
      .post(endPoints.getAllotedStudents, { token })
      .then((r) => {
        setLoading(false);
        setStudentCount(r.data.results.length);
      })
      .catch((e) => setLoading(false));
    axios.post(endPoints.getPlans, { token, activated: true }).then((r) => {
      !!r.data.results[0] &&
        setAllotState({
          ...allotState,
          plan_id: r.data.results[0].plan_id,
          license_purchased: parseInt(r.data.results[0].no_of_license),
        });
    });
    axios
      .post(endPoints.getProgramDetails, { token })
      .then((r) => setProgramList(r.data.results));
    getAllotedStudentListAPI();
  }, []);
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <FaUserPlus /> Student Allotment
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomModal
            dialogTitle={"Allot New Student"}
            isOpen={isAllot}
            setOpen={setAllot}
            cancelBtnText="Close"
            confirmBtnText={allotState.isModify ? "Modify" : "Add"}
            actionCallback={(e) => {
              if (allotState.isModify) {
                axios
                  .patch(endPoints.updateAllotedStudent, {
                    token,
                    ...allotState,
                  })
                  .then((r) => {
                    getAllotedStudentListAPI(allotState.program_details_id);
                    setAllotState({
                      ...allotState,
                      allotName: "",
                      allotEmail: "",
                      allotPhone: null,
                      allotStatus: "allotted",
                      isModify: false,
                    });
                  });
              } else {
                axios
                  .post(endPoints.allotNewStudent, {
                    token,
                    ...allotState,
                  })
                  .then((r) =>
                    getAllotedStudentListAPI(allotState.program_details_id)
                  );
              }
            }}
            mbContent={1}
            dialogContent={
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CustomInputField
                    label="Full Name"
                    fullWidth
                    value={allotState.allotName}
                    onChange={(e) => {
                      setAllotState({
                        ...allotState,
                        allotName: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputField
                    label="Email ID"
                    fullWidth
                    value={allotState.allotEmail}
                    onChange={(e) => {
                      setAllotState({
                        ...allotState,
                        allotEmail: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputField
                    label="Phone Number"
                    fullWidth
                    value={allotState.allotPhone}
                    onChange={(e) => {
                      setAllotState({
                        ...allotState,
                        allotPhone: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            }
          />

          <CustomInputAndSelectField
            getOptionLabel={(opt) =>
              opt.instituion_name
                ? `${opt.instituion_name} - ${opt.program_name} - ${new Date(
                    opt.year
                  ).getFullYear()}`
                : ""
            }
            options={programList}
            value={program}
            changeCallBack={(e, v) => {
              setProgram(v);
              !!v
                ? getAllotedStudentListAPI(v.program_details_id)
                : getAllotedStudentListAPI();
              !!v &&
                setAllotState({
                  ...allotState,
                  program_details_id: v.program_details_id,
                });
            }}
            label="Select College/University"
          />
          <CustomNotification
            open={!!mailMsg}
            alertMessage={mailMsg}
            severity={"success"}
            variant="filled"
            onClose={() => setMailMsg(false)}
          />
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <div className={classes.actionContainer}>
              <CustomButton
                disabled={
                  (program ? Object.keys(program).length === 0 : true) ||
                  studentCount >= allotState.license_purchased
                }
                text="Allot a new Student"
                variant="contained"
                onClick={(e) => {
                  setAllot(true);
                  setAllotState({
                    ...allotState,
                    allotName: "",
                    allotEmail: "",
                    allotPhone: null,
                    allotStatus: "allotted",
                    isModify: false,
                  });
                }}
                icon={<FaPlus />}
              />
              <CustomButton
                text="Invite All"
                disabled={studentList.length === 0}
                variant="contained"
                color="warning"
                icon={<FaMailBulk />}
                onClick={() => {
                  studentList
                    .filter((s) => s.allot_status !== "invited")
                    .forEach((st) => {
                      axios.post(endPoints.sendIndividual, {
                        token,
                        ...st,
                        allotName: st.allot_full_name,
                        allotEmail: st.allot_email,
                        allotPhone: st.allot_phone,
                        university: programList.find(
                          (pr) =>
                            pr.program_details_id === st.program_details_id
                        ).instituion_name,
                      });
                    });
                  getAllotedStudentListAPI(allotState.program_details_id);
                }}
              />
            </div>
            <Typography variant="body1">
              License Left :<b>{allotState.license_purchased - studentCount}</b>{" "}
            </Typography>
          </Box>
          <CustomTable
            cols={[
              "SL. No.",
              "Full Name",
              "Email ID",
              "Phone Number",
              "Status",
              "Action",
            ]}
            loading={loading}
            rows={studentList.map((st, idx) => [
              idx + 1,
              st.allot_full_name,
              st.allot_email,
              st.allot_phone ? st.allot_phone : "N/A",
              <CustomPill
                value={st.allot_status}
                color={
                  st.allot_status === "invited"
                    ? "success"
                    : st.allot_status === "activated"
                    ? "error"
                    : "info"
                }
              />,
              <span className={classes.tableAction}>
                {isMailSent === idx ? (
                  <CircularProgress
                    disableShrink
                    size={20}
                    color="primary"
                    thickness={8}
                  />
                ) : (
                  <CustomButton
                    text={"Invite"}
                    variant="contained"
                    disabled={st.allot_status === "activated"}
                    actionCallBack={() => {
                      setMailSent(idx);
                      axios
                        .post(endPoints.sendIndividual, {
                          token,
                          ...st,
                          allotName: st.allot_full_name,
                          allotEmail: st.allot_email,
                          allotPhone: st.allot_phone,
                          university: programList.find(
                            (pr) =>
                              pr.program_details_id === st.program_details_id
                          ).instituion_name,
                        })
                        .then((r) => {
                          setMailSent(false);
                          setMailMsg("Email Sent Successfully.");
                          getAllotedStudentListAPI(
                            allotState.program_details_id
                          );
                        })
                        .catch((e) => {
                          setMailSent(false);
                          setMailMsg("Error! Try Again");
                        });
                    }}
                  />
                )}
                {st.allot_status !== "activated" && (
                  <CustomIconButton
                    icon={<FaPen />}
                    onClick={(e) => {
                      setAllot(true);
                      delete st["program_details_id"];
                      setAllotState({
                        ...st,
                        ...allotState,
                        allotName: st.allot_full_name,
                        allotEmail: st.allot_email,
                        allotPhone: st.allot_phone,
                        allotStatus: st.allot_status,
                        isModify: true,
                      });
                    }}
                    size="small"
                    color="warning"
                  />
                )}{" "}
                {st.allot_status !== "activated" && (
                  <CustomIconButton
                    icon={<FaTrash />}
                    size="small"
                    onClick={(e) => {
                      delete st["program_details_id"];
                      axios
                        .patch(endPoints.updateAllotedStudent, {
                          token,
                          del_flag: 1,
                          inst_allot_id: st.inst_allot_id,
                        })
                        .then((r) =>
                          getAllotedStudentListAPI(
                            allotState.program_details_id
                          )
                        );
                    }}
                    color="error"
                  />
                )}
              </span>,
            ])}
          />
        </div>
      </div>
    </div>
  );
}
