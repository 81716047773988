import { makeStyles } from "@mui/styles";

const customTableStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
  },
  tableHead: {
    background: theme.palette.primary.lighter,
    "& th": {
      border: "1px solid #f1f3f4",
      color: theme.palette.primary.main,
      background: theme.palette.primary.lighter,
      whiteSpace: "nowrap",
    },
  },
  tableBody: {
    "& td": {
      border: "1px solid #f1f3f4 !important",
      whiteSpace: "nowrap",
    },
  },
  tableName: {
    background: theme.palette.primary.main,
    padding: 16,
    fontWeight: "bold",
    color: theme.palette.secondary.lighter,
  },
  loader: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "20vh",
    alignItems: "center",
  },
}));

export default customTableStyles;
