import { Divider, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { FaChartPie, FaTable, FaTimes } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import reportStyles from "../../styles/pages/Reports/index.style";
import CustomReport from "./CustomReport";

export default function ReportsPage() {
  const classes = reportStyles();
  const token = localStorage.getItem("token");
  const [reports, setReports] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [isDetail, setDetail] = React.useState(false);
  const [sort, setSort] = React.useState("Full name");
  React.useEffect(() => {
    axios.post(endPoints.getReportDetails, { token }).then((r) => {
      setReports(r.data.results);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataLoopBasic = (reports) => {
    let acc = {};
    let duplicateCheck = {};
    if (reports.length > 0) {
      reports.map((curr) => {
        const { alloted_user_id } = curr;
        if (acc[alloted_user_id] === undefined) {
          duplicateCheck[curr.resume_id] = "resume_exist";
          acc[alloted_user_id] = [
            [
              {
                rowCount: curr.resume_count,
                row: curr.instituion_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.program_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.year,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_full_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_email,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_phone ? curr.allot_phone : "N/A",
              },
              {
                rowCount: curr.resume_count,
                row: curr.ai_credits,
              },
              {
                rowCount: 1,
                row: curr.sc_api_overall
                  ? `${curr.sc_api_overall} / 100`
                  : "N/A",
              },
              {
                rowCount: curr.resume_count,
                row: curr.resume_download,
              },
            ],
          ];
        } else if (acc[alloted_user_id] && !duplicateCheck[curr.resume_id]) {
          acc[alloted_user_id] = [
            ...acc[alloted_user_id],
            [
              {
                rowCount: 1,
                row: curr.sc_api_overall
                  ? `${curr.sc_api_overall} / 100`
                  : "N/A",
              },
            ],
          ];
        }
        return "";
      });
    }
    if (sort === "AI Credit Used") {
      return Object.values(acc).sort((a, b) => b[0][6].row - a[0][6].row);
    } else if (sort === "Resume Downloaded") {
      return Object.values(acc).sort((a, b) => b[0][8].row - a[0][8].row);
    } else if (sort === "Email") {
      return Object.values(acc).sort((a, b) =>
        a[0][4].row.localeCompare(b[0][4].row)
      );
    } else if (sort === "Full name") {
      return Object.values(acc).sort((a, b) =>
        a[0][3].row.localeCompare(b[0][3].row)
      );
    } else if (sort === "Resume Score") {
      return Object.values(acc).sort((a, b) => {
        if (b[0][7].row === "N/A") {
          return -1;
        } else if (a[0][7].row === "N/A") {
          return 1;
        } else {
          return b[0][7].row.split("/")[0] - a[0][7].row.split("/")[0];
        }
      });
    } else {
      return Object.values(acc);
    }
  };

  const handleDataLoop = (reports) => {
    let acc = {};
    let duplicateCheck = {};
    if (reports.length > 0) {
      reports.map((curr) => {
        const { alloted_user_id } = curr;
        if (acc[alloted_user_id] === undefined) {
          duplicateCheck[curr.resume_id] = "resume_exist";
          acc[alloted_user_id] = [
            [
              {
                rowCount: curr.resume_count,
                row: curr.instituion_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.program_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.year,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_full_name,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_email,
              },
              {
                rowCount: curr.resume_count,
                row: curr.allot_phone ? curr.allot_phone : "N/A",
              },
              {
                rowCount: 1,
                row: curr.ed_major_special ? curr.ed_major_special : "N/A",
              },
              {
                rowCount: curr.resume_count,
                row: curr.resume_count,
              },
              {
                rowCount: curr.resume_count,
                row: curr.ai_credits,
              },
              {
                rowCount: curr.resume_count,
                row: curr.resume_guide_book,
              },
              {
                rowCount: 1,
                row: `${
                  curr.completion_pd +
                  curr.completion_ed +
                  curr.completion_sk +
                  curr.completion_ac +
                  curr.completion_ex +
                  curr.completion_xt
                }/6`,
              },
              {
                rowCount: 1,
                row: curr.sc_api_overall
                  ? `${curr.sc_api_overall} / 100`
                  : "N/A",
              },
              {
                rowCount: curr.resume_count,
                row: curr.resume_download,
              },
              {
                rowCount: 1,
                row: curr.resume_type === "UP" ? "Uploaded" : "Created",
              },
            ],
          ];
        } else if (acc[alloted_user_id] && !duplicateCheck[curr.resume_id]) {
          acc[alloted_user_id] = [
            ...acc[alloted_user_id],
            [
              {
                rowCount: 1,
                row: curr.ed_major_special ? curr.ed_major_special : "N/A",
              },
              {
                rowCount: 1,
                row: `${
                  curr.completion_pd +
                  curr.completion_ed +
                  curr.completion_sk +
                  curr.completion_ac +
                  curr.completion_pd +
                  curr.completion_xt
                }/6`,
              },
              {
                rowCount: 1,
                row: curr.sc_api_overall
                  ? `${curr.sc_api_overall} / 100`
                  : "N/A",
              },
              {
                rowCount: 1,
                row: curr.resume_type === "UP" ? "Uploaded" : "Created",
              },
            ],
          ];
        }
        return "";
      });
    }
    if (sort === "AI Credit Used") {
      return Object.values(acc).sort((a, b) => b[0][8].row - a[0][8].row);
    } else if (sort === "Resume Downloaded") {
      return Object.values(acc).sort((a, b) => b[0][12].row - a[0][12].row);
    } else if (sort === "Email") {
      return Object.values(acc).sort((a, b) =>
        a[0][4].row.localeCompare(b[0][4].row)
      );
    } else if (sort === "Resume Score") {
      return Object.values(acc).sort((a, b) => {
        if (b[0][11].row === "N/A") {
          return -1;
        } else if (a[0][11].row === "N/A") {
          return 1;
        } else {
          return b[0][11].row.split("/")[0] - a[0][11].row.split("/")[0];
        }
      });
    } else if (sort === "Resume Creation Type") {
      return Object.values(acc).sort((a, b) =>
        a[0][13].row.localeCompare(b[0][13].row)
      );
    } else if (sort === "Full name") {
      return Object.values(acc).sort((a, b) =>
        a[0][3].row.localeCompare(b[0][3].row)
      );
    } else {
      return Object.values(acc);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <FaChartPie /> Reports
          </Typography>

          {isDetail ? (
            <CustomButton
              text={"Close Detailed Report"}
              variant="contained"
              size="large"
              color="error"
              icon={<FaTimes />}
              onClick={(e) => {
                setDetail(false);
                console.log(isDetail);
              }}
            />
          ) : (
            <CustomButton
              text={"See Detailed Report"}
              variant="contained"
              size="large"
              icon={<FaTable />}
              onClick={(e) => {
                setDetail((prev) => !prev);
                console.log(isDetail);
              }}
            />
          )}
        </div>

        <Divider className={classes.divider} />
        <div className={classes.filterDiv}>
          <CustomInputField
            label="Filter By Full name"
            fullWidth
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          <CustomInputAndSelectField
            label={"Sort By"}
            options={
              isDetail
                ? [
                    "Full name",
                    "Email",
                    "Resume Downloaded",
                    "AI Credit Used",
                    "Resume Score",
                    "Resume Creation Type",
                  ]
                : [
                    "Full name",
                    "Email",
                    "Resume Downloaded",
                    "AI Credit Used",
                    "Resume Score",
                  ]
            }
            value={sort}
            changeCallBack={(e, v) => {
              setSort(v);
            }}
            fullWidth
          />
        </div>
        <div className={classes.contentMain}>
          {isDetail ? (
            <CustomReport
              cols={[
                "SL. No.",
                "College/University",
                "Program",
                "Year",
                "Full Name",
                "Email ID",
                "Phone number",
                "Major/Specialization",
                "Resume Created",
                "AI Credits Used",
                "Resume Guide Book",
                "Section Completion",
                "Resume Score",
                "Resume Downloaded",
                "Resume Creation Type/Status",
              ]}
              checkSpanList={4} // No of cols which has embedded rows
              rowSpanList={reports.map((rp) => rp.resume_count)}
              rows={handleDataLoop(
                reports.filter(
                  (d) =>
                    d.allot_full_name
                      ?.toLowerCase()
                      ?.includes(filter.toLowerCase()) ||
                    d.allot_email?.toLowerCase()?.includes(filter.toLowerCase())
                )
              )}
            />
          ) : (
            <CustomReport
              cols={[
                "SL. No.",
                "College/University",
                "Program",
                "Year",
                "Full Name",
                "Email ID",
                "Phone number",
                "AI Credits Used",
                "Resume Score",
                "Resume Downloaded",
              ]}
              checkSpanList={1}
              rowSpanList={reports.map((rp) => rp.resume_count)}
              rows={
                isDetail
                  ? handleDataLoop(
                      reports.filter(
                        (d) =>
                          d.allot_full_name
                            ?.toLowerCase()
                            ?.includes(filter.toLowerCase()) ||
                          d.allot_email
                            ?.toLowerCase()
                            ?.includes(filter.toLowerCase())
                      )
                    )
                  : handleDataLoopBasic(
                      reports.filter(
                        (d) =>
                          d.allot_full_name
                            ?.toLowerCase()
                            ?.includes(filter.toLowerCase()) ||
                          d.allot_email
                            ?.toLowerCase()
                            ?.includes(filter.toLowerCase())
                      )
                    )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
