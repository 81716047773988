import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, Typography } from "@mui/material";
import customTableStyles from "../../styles/components/CustomTable/index.styles";
import EmptyPreview from "../../components/EmptyPreview/Index";

export default function CustomReport({
  rows,
  cols,
  tableName,
  checkSpanList,
  loading,
}) {
  const classes = customTableStyles();
  console.log(rows);
  return (
    <TableContainer
      component={Paper}
      sx={{
        minHeight: 500,
        maxHeight: "calc(100vh - 30vh)",
        width: "100%",
      }}
      className={classes.tableContainer}
    >
      {tableName && (
        <Typography
          variant="body"
          component="p"
          color={"primary"}
          className={classes.tableName}
        >
          {tableName}
        </Typography>
      )}
      <Table stickyHeader>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {cols.map((col, idx) => (
              <TableCell
                width={idx === 0 && 80}
                align={"center"}
                key={idx}
                rowSpan={3}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {loading
            ? ""
            : rows.map((rowList, idx) => (
                <>
                  {rowList.map((row, i) => (
                    <TableRow>
                      {row.length > checkSpanList && (
                        <TableCell
                          align={"center"}
                          key={idx}
                          rowSpan={row[0].rowCount}
                        >
                          {idx + 1}
                        </TableCell>
                      )}
                      {row.map((r, i) => (
                        <TableCell
                          align={"center"}
                          key={i}
                          rowSpan={r.rowCount}
                        >
                          {r.row}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ))}
        </TableBody>
      </Table>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress
            disableShrink
            size={20}
            color="warning"
            thickness={8}
          />
        </div>
      )}
      {!rows.length && !loading && <EmptyPreview />}
    </TableContainer>
  );
}
