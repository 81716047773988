import { Link as RouterLink } from "react-router-dom";
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import MHidden from "../../components/@material-extend/MHidden";
import LoginForm from "./form";
// import AuthSocial from "../components/authentication/AuthSocial";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  "& img": {
    margin: "0px auto",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
  color: theme.palette.secondary.lighter,
  background: theme.palette.primary.main,
  "& img": {
    background: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    padding: 20,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Register">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown">
        <SectionStyle>
          <img
            src="	https://vipapplicant.com/static/media/VIPAPPLICANT.506696ba.png"
            alt="login"
            width={"55%"}
          />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Institution Dashboard
          </Typography>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="md">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Register
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

          <MHidden width="xsDown">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Already have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/">
                Sign In
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
