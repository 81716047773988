import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

export default function CustomSlider({ value, stepCount, min, max, onChange }) {
  return (
    <Box display={"flex"} gap={3} alignItems="center">
      {value}
      <Slider
        aria-label="Small steps"
        value={value}
        getAriaValueText={valuetext}
        onChange={(e, v) => onChange(v)}
        step={stepCount}
        marks
        min={min}
        max={max}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
