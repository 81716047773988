import React from "react";
import { AiFillFlag, AiFillHome } from "react-icons/ai";
import { BsFillPatchCheckFill, BsListCheck } from "react-icons/bs";
import {
  FaBoxes,
  FaCalculator,
  FaCcVisa,
  FaChartPie,
  FaDashcube,
  FaPoll,
  FaPortrait,
  FaScroll,
  FaTag,
  FaUniversity,
  FaUserPlus,
  FaWpforms,
} from "react-icons/fa";
import { GoSettings } from "react-icons/go";
// ----------------------------------------------------------------------

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    value: 0,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  {
    value: 1,
    name: "country",
    title: <>Plan</>,
    path: "/plan",
    icon: <FaBoxes className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "countryList",
    title: <>Institution Details</>,
    path: "/institution_details",
    icon: <FaUniversity className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "visaCategory",
    title: <>Scoring Criteria</>,
    path: "/scoring_criteria",
    icon: <GoSettings className="sidebar-icon" />,
  },
  {
    value: 4,
    name: "visaPricing",
    title: <>Student Allotment</>,
    path: "/student_allotment",
    icon: <FaUserPlus className="sidebar-icon" />,
  },
  {
    value: 5,
    name: "calculatorTool",
    title: <>Reports</>,
    path: "/reports",
    icon: <FaChartPie className="sidebar-icon" />,
  },
];

export const bottomNavigationConfig = [
  {
    value: 0,
    name: "home",
    title: <>Home</>,
    path: "/dashboard",
    icon: <AiFillHome className="sidebar-icon" />,
  },
  {
    value: 1,
    name: "dashboard",
    title: <>Dashboard</>,
    path: "/dashboard",
    icon: <FaDashcube className="sidebar-icon" />,
  },
  {
    value: 2,
    name: "profile",
    title: (
      <>
        Profile <span className="alert-helper-ylw">Imp</span>
      </>
    ),
    path: "/profile",
    icon: <FaPortrait className="sidebar-icon" />,
  },
  {
    value: 3,
    name: "jobfeed",
    title: (
      <>
        JobFeed <span className="alert-helper">New</span>
      </>
    ),
    path: "/jobfeed",
    icon: <FaScroll className="sidebar-icon" />,
  },
  {
    value: 4,
    name: "resources",
    title: <>Resources</>,
    path: "/resource",
    icon: <FaPoll className="sidebar-icon" />,
  },
];
export default sidebarConfig;
