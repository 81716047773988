import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import customTableStyles from "../../styles/components/CustomTable/index.styles";
import { CircularProgress, Typography } from "@mui/material";
import EmptyPreview from "../EmptyPreview/Index";

export default function CustomTable({
  rows,
  cols,
  tableName,
  firstSectionAlign,
  loading,
  rowSpanList = [],
  checkSpanList = [],
}) {
  const classes = customTableStyles();
  return (
    <TableContainer
      component={Paper}
      sx={{
        minWidth: 500,
        maxHeight: 500,
        width: "100%",
      }}
      className={classes.tableContainer}
    >
      {tableName && (
        <Typography
          variant="body"
          component="p"
          color={"primary"}
          className={classes.tableName}
        >
          {tableName}
        </Typography>
      )}
      <Table stickyHeader>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {cols.map((col, idx) => (
              <TableCell
                width={idx === 0 && 80}
                align={"center"}
                key={idx}
                rowSpan={3}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {loading
            ? ""
            : rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row.map((r, i) => (
                    <TableCell
                      align={"center"}
                      key={i}
                      // rowSpan={
                      //   checkSpanList.includes(cols[idx]) ? 1 : rowSpanList[idx]
                      // }
                    >
                      {r}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress
            disableShrink
            size={20}
            color="warning"
            thickness={8}
          />
        </div>
      )}
      {!rows.length && !loading && <EmptyPreview />}
    </TableContainer>
  );
}
