import React from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { Paper, Typography } from "@mui/material";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";

export default function LoginForm() {
  const classes = layoutStyles();
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    phone: "",
  });
  return (
    <Paper className={classes.layOutPaper}>
      {error && (
        <Typography variant="body1" fontWeight={"bold"} color="error" mb={3}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CustomInputField
            label="Full Name"
            fullWidth
            helperText="Enter your name"
            value={state.fullName}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                fullName: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            label="mobile number"
            fullWidth
            helperText="Enter your 10 digit mobile number"
            value={state.phone}
            type={"number"}
            inputProps={{
              min: 0,
            }}
            error={state.phone && state.phone.length > 10}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                phone: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            label="email"
            fullWidth
            helperText="Enter your valid email address"
            error={
              state.email &&
              !state.email.match(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
              )
            }
            value={state.email}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                email: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            helperText={"Enter a Strong Password"}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                password: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            label="Confirm Password"
            type="password"
            fullWidth
            error={state.password !== state.confirmPassword}
            helperText={"Re-Enter Your Password"}
            value={state.confirmPassword}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                confirmPassword: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            text={"Sign Up"}
            fullWidth
            variant="contained"
            onClick={(e) => {
              if (Object.values(state).includes("")) {
                setError(
                  "Missing field found, Please! Provide correct credentials."
                );
              } else {
                if (
                  state.phone.length <= 10 &&
                  state.email.match(
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                  ) &&
                  state.password === state.confirmPassword
                ) {
                  axios.post(endPoints.signUp, state).then((r) => {
                    localStorage.setItem("token", r.data.results.token);
                    navigate("/dashboard");
                  });
                } else {
                  setError("Required Fields are filled incorrectly!");
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
