import React from "react";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router";
import layoutStyles from "../../styles/layout/sidebar.boost.style";
import { Paper, Typography } from "@mui/material";
import axios from "axios";
import endPoints from "../../config/endPoint";

export default function ForgetPassComponent() {
  const navigate = useNavigate();
  const classes = layoutStyles();
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPass: "",
    otp: null,
  });

  const { email, token } = useParams();
  return (
    <Paper className={classes.layOutPaper}>
      {error && (
        <Typography variant="body1" fontWeight={"bold"} color="error" mb={3}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomInputField
            label="email"
            fullWidth
            value={email}
            error={
              state.email &&
              !state.email.match(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
              )
            }
            helperText="Enter your valid email address"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            label="Password"
            type="password"
            fullWidth
            value={state.password}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                password: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            label="Confirm Password"
            type="password"
            fullWidth
            value={state.confirmPass}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                confirmPass: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            label="OTP"
            fullWidth
            value={state.otp}
            onChange={(e) => {
              setError("");
              setState({
                ...state,
                otp: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            text={"Update Password & Login"}
            fullWidth
            variant="contained"
            color="info"
            onClick={(e) => {
              axios
                .patch(endPoints.updatePassword, {
                  password: state.password,
                  otp: state.otp,
                  email: email,
                  token: token,
                })
                .then((r) => {
                  console.log(r.data.results);
                  localStorage.setItem("token", r.data.results.token);
                  navigate("/dashboard");
                });
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
