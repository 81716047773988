const scoringWeightages = (scoringState) => {
  return [
    {
      sci_param: "total_bullet_count",
      sci_val: 24,
    },
    {
      sci_param: "total_words",
      sci_val: 238,
    },
    {
      sci_param: "overused_wd_limit",
      sci_val: 3,
    },
    {
      sci_param: "overused_wd_count",
      sci_val: 8,
    },
    {
      sci_param: "total_qnt_count",
      sci_val: 1,
    },
    {
      sci_param: "total_skill_count",
      sci_val: 6,
    },
    {
      sci_param: "total_actionverb_count",
      sci_val: 1,
    },
    {
      sci_param: "hard_skill_count",
      sci_val: 4,
    },
    {
      sci_param: "soft_skill_count",
      sci_val: 2,
    },
    {
      sci_param: "tenth_sc",
      sci_val: 80,
    },
    {
      sci_param: "twelveth_sc",
      sci_val: 70,
    },
    {
      sci_param: "bachelor_sc",
      sci_val: 78,
    },
    {
      sci_param: "master_sc",
      sci_val: 52,
    },
    {
      sci_param: "diploma_sc",
      sci_val: 65,
    },
    {
      sci_param: "total_exp_month",
      sci_val: 15,
    },
    {
      sci_param: "project_count",
      sci_val: 2,
    },
    {
      sci_param: "total_internship_month",
      sci_val: 2,
    },
    {
      sci_param: "certification_count",
      sci_val: 3,
    },
    {
      sci_param: "certification_source",
      sci_val: "yes",
    },
    {
      sci_param: "publication_count",
      sci_val: 5,
    },
    {
      sci_param: "awards_count",
      sci_val: 2,
    },
    {
      sci_param: "wtg_impact",
      sci_val: scoringState.impact,
    },
    {
      sci_param: "wtg_total_no_of_bullet",
      sci_val: 25,
    },
    {
      sci_param: "wtg_start_with_action_verbs",
      sci_val: 50,
    },
    {
      sci_param: "wtg_quantification",
      sci_val: 25,
    },
    {
      sci_param: "wtg_over_used_words",
      sci_val: 0,
    },
    {
      sci_param: "wtg_no_of_words",
      sci_val: 0,
    },
    {
      sci_param: "wtg_skills",
      sci_val: scoringState.skills,
    },
    {
      sci_param: "wtg_hard_skills",
      sci_val: 65,
    },
    {
      sci_param: "wtg_soft_skills",
      sci_val: 35,
    },
    {
      sci_param: "wtg_academic",
      sci_val: scoringState.achievement,
    },
    {
      sci_param: "wtg_masters",
      sci_val: 0,
    },
    {
      sci_param: "wtg_bachelors",
      sci_val: 60,
    },
    {
      sci_param: "wtg_twelve",
      sci_val: 25,
    },
    {
      sci_param: "wtg_tenth",
      sci_val: 15,
    },
    {
      sci_param: "wtg_diploma",
      sci_val: 0,
    },
    {
      sci_param: "wtg_experience",
      sci_val: scoringState.internship,
    },
    {
      sci_param: "wtg_total_months",
      sci_val: 0,
    },
    {
      sci_param: "wtg_projects",
      sci_val: 40,
    },
    {
      sci_param: "wtg_internships",
      sci_val: 60,
    },
    {
      sci_param: "wtg_inititaives",
      sci_val: scoringState.award,
    },
    {
      sci_param: "wtg_certifications",
      sci_val: 45,
    },
    {
      sci_param: "wtg_certification_source",
      sci_val: 15,
    },
    {
      sci_param: "wtg_publications",
      sci_val: 25,
    },
    {
      sci_param: "wtg_awards",
      sci_val: 15,
    },
  ];
};

export default scoringWeightages;
