const apiGateWayUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_URL + "/api/v1"
    : process.env.REACT_APP_BACKEND_URL + "/api/v1";

const endPoints = {
  apiGateWay: apiGateWayUrl,
  isAuth: `${apiGateWayUrl}/isAuth`,
  signIn: `${apiGateWayUrl}/signIn`,
  signUp: `${apiGateWayUrl}/signUp`,
  getPlans: `${apiGateWayUrl}/getPlans`,
  getPlanList: `${apiGateWayUrl}/getPlanList`,
  purchasePlan: `${apiGateWayUrl}/registerPlan`,
  getInstitutionDetails: `${apiGateWayUrl}/getInstitutionDetails`,
  updateInstitutionDetails: `${apiGateWayUrl}/updateInstitutionDetails`,
  registerInstitutionDetails: `${apiGateWayUrl}/registerInstitutionDetails`,
  getProgramDetails: `${apiGateWayUrl}/getProgramDetails`,
  registerProgramDetails: `${apiGateWayUrl}/registerProgramDetails`,
  updateProgramDetails: `${apiGateWayUrl}/updateProgramDetails`,
  getScoringDetails: `${apiGateWayUrl}/getScoringDetails`,
  addScoringDetails: `${apiGateWayUrl}/addScoringDetails`,
  updateScoringDetails: `${apiGateWayUrl}/updateScoringDetails`,
  getAllotedStudents: `${apiGateWayUrl}/getAllotedStudents`,
  allotNewStudent: `${apiGateWayUrl}/allotNewStudent`,
  updateAllotedStudent: `${apiGateWayUrl}/updateAllotedStudent`,
  sendIndividual: `${apiGateWayUrl}/sendIndividual`,
  sendBulk: `${apiGateWayUrl}/sendBulk`,
  forgetPassword: `${apiGateWayUrl}/forgetPassword`,
  updatePassword: `${apiGateWayUrl}/updatePassword`,
  getReportDetails: `${apiGateWayUrl}/getReportData`,
};

export default endPoints;
