import { Typography } from "@mui/material";
import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
const provideMoreDetails = (plan_id, classes, premiumPlan) => {
  switch (plan_id) {
    case 4:
      return (
        <div>
          <Typography variant="h6" className={classes.priceTag}>
            <text className={classes.paidText}>₹{premiumPlan.plan_price}</text>
            <span>/{premiumPlan.plan_tenure} days</span>
          </Typography>
          <Typography className={classes.bulletContainer}>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Unlimited Use of
              Resume Builder
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Make 15
              Professional Resume
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Download or Email
              in PDF, Doc or Text format
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> 15 Resume Download
            </p>

            <p>
              <FaCheckCircle className={classes.checkIcon} /> 350 AI Credits
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> AI Support to
              Write Professional Summary
            </p>

            <p>
              <FaCheckCircle className={classes.checkIcon} /> AI Support to
              Write Bullet Points
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Dynamic
              Suggestions Support to Optimize Bullet Points
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Access to 7 Resume
              Templates
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Access to Resume
              Guide Book
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> One 30 Mins
              Session with an Expert Coach
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Job Matching Score
              (Upcoming Feature)
            </p>
          </Typography>
        </div>
      );

    default:
      return (
        <div>
          <Typography variant="h6" className={classes.priceTag}>
            <text className={classes.paidText}>₹{premiumPlan.plan_price}</text>
            <span> /{premiumPlan.plan_tenure} days</span>
          </Typography>
          <Typography className={classes.bulletContainer}>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Unlimited Use of
              Resume Builder
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Make 5
              Professional Resume
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Download or Email
              in PDF, Doc or Text format
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> 10 Resume Download
            </p>

            <p>
              <FaCheckCircle className={classes.checkIcon} /> 50 AI Credits
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> AI Support to
              Write Professional Summary
            </p>

            <p>
              <FaCheckCircle className={classes.checkIcon} /> AI Support to
              Write Bullet Points
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Dynamic
              Suggestions Support to Optimize Bullet Points
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Access to 5 Resume
              Templates
            </p>
            <p>
              <FaCheckCircle className={classes.checkIcon} /> Access to Resume
              Guide Book
            </p>
            <p>
              <FaTimesCircle className={classes.rejectIcon} /> Access to Expert
              Session
            </p>
            <p>
              <FaTimesCircle className={classes.rejectIcon} /> Job Matching
              Score (Upcoming Feature)
            </p>
          </Typography>
        </div>
      );
  }
};

export default provideMoreDetails;
