import { CircularProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import endPoints from "./config/endPoint";
import DashBoard from "./pages/Dashboard/Index";
import InstitutionDetailsPage from "./pages/InstitutionDetails/Index";
import Login from "./pages/Login/Login";
import PlanPage from "./pages/Plan/Index";
import Register from "./pages/Register/Register";
import ReportsPage from "./pages/Reports/Index";
import ScoringCriteriaPage from "./pages/Scoring/Index";
import StudentAllotmentPage from "./pages/StudentAllotment/Index";
import useStyles from "./styles/main.styles";
import GlobalStyles from "./theme/globalStyles";
import ThemeConfig from "./theme/index";

const App = () => {
  const isExist = localStorage.getItem("token");
  const classes = useStyles();
  const [isAuth, setIsAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(true);
    axios
      .post(`${endPoints.isAuth}`, { token: isExist })
      .then((r) => {
        setIsAuth(true);
        setLoading(false);
      })
      .catch((e) => {
        localStorage.removeItem("token");
        setIsAuth(false);
        setLoading(false);
      });
  }, [isExist]);
  let routes = useRoutes([
    {
      path: "/",
      element: !isAuth ? <Login /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/:token/:email",
      element: !isAuth ? <Login /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/register",
      element: !isAuth ? <Register /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/dashboard",
      element: isAuth ? <DashBoard /> : <Navigate to={"/"} />,
    },
    {
      path: "/plan",
      element: isAuth ? <PlanPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/institution_details",
      element: isAuth ? <InstitutionDetailsPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/scoring_criteria",
      element: isAuth ? <ScoringCriteriaPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/student_allotment",
      element: isAuth ? <StudentAllotmentPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/reports",
      element: isAuth ? <ReportsPage /> : <Navigate to={"/"} />,
    },
    { path: "*", element: <Navigate to={"/404"} /> },
  ]);
  if (loading)
    return (
      <div className={classes.loader}>
        <CircularProgress
          disableShrink
          size={35}
          color="primary"
          thickness={6}
        />
      </div>
    );
  return <div className={classes.headContainer}>{routes}</div>;
};

const AppWrapper = () => {
  return (
    <div className="App">
      {/* <CssBaseline /> */}
      <ThemeConfig>
        <GlobalStyles />
        <App />
      </ThemeConfig>
    </div>
  );
};

export default AppWrapper;
