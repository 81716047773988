import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaBoxes, FaCheck, FaCheckCircle, FaPen } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomModal from "../../components/CustomModal/Index";
import CustomNotification from "../../components/CustomNotification/Index";
import CustomPreview from "../../components/CustomPreview/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import planStyles from "../../styles/pages/Plan/index.style";
import provideMoreDetails from "./moreDetails";

export default function PlanPage() {
  const classes = planStyles();
  const [selection, setSelection] = React.useState(null);
  const token = localStorage.getItem("token");
  const [planList, setPlanList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState({});
  const [noOfLicense, setNoOfLicense] = React.useState(null);
  const [pendingPlans, setPendingPlan] = React.useState([]);
  const [notify, setNotify] = React.useState(false);
  React.useEffect(() => {
    axios
      .post(endPoints.getPlanList, { token })
      .then((r) => setPlanList(r.data.results));
    axios.post(endPoints.getPlans, { token }).then((r) => {
      setPendingPlan(r.data.results);
    });
  }, []);

  const handlePurchasePlan = () => {
    axios
      .post(endPoints.purchasePlan, {
        ...selectedPlan,
        token,
        noOfLicense,
      })
      .then((r) => {
        setPendingPlan([...r.data.results]);
        setSelectedPlan({});
        setNoOfLicense(null);
      });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <FaBoxes /> Plan
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomModal
            dialogTitle={selectedPlan.plan_name}
            cancelBtnText="Close"
            isOpen={open}
            setOpen={setOpen}
            dialogContentText={provideMoreDetails(
              selectedPlan.plan_id,
              classes,
              selectedPlan
            )}
          />
          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={7}>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.planContainer}>
                  {planList.map((pl, idx) => (
                    <Paper
                      className={classNames(
                        classes.paper,
                        pl.plan_id === selection && classes.paper2
                      )}
                      key={idx}
                      onClick={() => {
                        setSelection(pl.plan_id);

                        setSelectedPlan(pl);
                      }}
                    >
                      {pl.plan_id === selection && (
                        <FaCheckCircle
                          fontSize={"1.4rem"}
                          color="green"
                          className="icon"
                        />
                      )}
                      <Typography variant="h4">{pl.plan_name}</Typography>
                      <Typography variant="body1" mb={2} className="subText">
                        INR {pl.plan_price} / {pl.plan_tenure} Days / License
                      </Typography>
                      <CustomButton
                        variant="outlined"
                        color="warning"
                        fullWidth
                        text={"More Details"}
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </Paper>
                  ))}
                </div>
                <CustomInputField
                  value={noOfLicense}
                  onChange={(e) => {
                    setNoOfLicense(e.target.value);
                  }}
                  label="Number of License"
                  fullWidth
                  type={"number"}
                  inputProps={{
                    min: 0,
                  }}
                />
                <CustomButton
                  className={classes.actionBtn}
                  text={"Purchase License"}
                  disabled={pendingPlans.length > 0}
                  variant="contained"
                  fullWidth
                  onClick={handlePurchasePlan}
                />
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper elevation={3} className={classes.paper}>
                <Typography
                  variant="h4"
                  display={"flex"}
                  justifyContent="space-between"
                >
                  <span>Plan Subscribed</span>{" "}
                  <CustomButton
                    variant="contained"
                    color="warning"
                    text="Update Plan"
                    icon={<FaPen />}
                    onClick={() => {
                      setNotify(
                        "To add more license please contact your relationship manager."
                      );
                    }}
                  />
                </Typography>
                {pendingPlans.length > 0 ? (
                  <Paper className={classes.paper}>
                    <Typography variant="body1">
                      Plan Name:{" "}
                      <b>
                        {pendingPlans[0].plan_id === 4
                          ? "VA Platinum"
                          : "VA Professional"}
                      </b>
                    </Typography>
                    <Typography>
                      License Purchased: <b>{pendingPlans[0].no_of_license}</b>
                    </Typography>
                    <Typography>
                      License Used: <b>{pendingPlans[0].no_of_license_used}</b>
                    </Typography>
                    <Typography>
                      Start Date:{" "}
                      <b>{pendingPlans[0].start_date.slice(0, 10)}</b>
                    </Typography>
                    <Typography>
                      End Date: <b>{pendingPlans[0].end_date.slice(0, 10)}</b>
                    </Typography>
                  </Paper>
                ) : (
                  <CustomPreview
                    url={"/static/noActivePlan.svg"}
                    message="No Active Plans"
                  />
                )}
                <CustomNotification
                  open={!!notify}
                  alertMessage={notify}
                  severity={"warning"}
                  variant="filled"
                  onClose={() => setNotify(false)}
                />
                <CustomButton
                  text={
                    pendingPlans.length > 0
                      ? pendingPlans[0].plan_active === "inactive"
                        ? "Activate Now"
                        : "Activated"
                      : "No Plans"
                  }
                  variant="contained"
                  color={
                    pendingPlans.length > 0
                      ? pendingPlans[0].plan_active === "inactive"
                        ? "primary"
                        : "success"
                      : "primary"
                  }
                  icon={
                    pendingPlans.length > 0 ? (
                      pendingPlans[0].plan_active === "inactive" ? (
                        ""
                      ) : (
                        <FaCheck />
                      )
                    ) : (
                      ""
                    )
                  }
                  fullWidth
                  onClick={() => {
                    pendingPlans.length > 0
                      ? pendingPlans[0].plan_active === "inactive"
                        ? setNotify(
                            "Please ask your relationship manager to activate your plan."
                          )
                        : setNoOfLicense(null)
                      : setNoOfLicense(null);
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
