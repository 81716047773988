import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import {
  FaDollarSign,
  FaNewspaper,
  FaPaperclip,
  FaUserCheck,
} from "react-icons/fa";
import DashboardLayout from "../../layouts";
import useStyles from "../../styles/pages/Dashboard/index.style";
import DashboardCard from "./component/card";

export default function DashBoard() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <AiFillHome /> Dashboard
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Typography variant="span">
            Welcome to Institution Dashboard!
          </Typography>
          <img src="/static/dashboard.svg" width={"70%"} />
        </div>
      </div>
    </div>
  );
}
