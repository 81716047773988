import React from "react";

export default function CustomPreview({ url, message }) {
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bold",
        color: "GrayText",
        fontSize: "1.2rem",
        marginBottom: "20px",
      }}
    >
      <img
        style={{
          margin: "auto",
        }}
        src={url}
        alt="not found"
        width={"50%"}
      />
      {message}
    </div>
  );
}
