import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaPen, FaPlus, FaTrash, FaUniversity } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomDatePicker from "../../components/CustomDateField/Index";
import CustomIconButton from "../../components/CustomIcon/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomModal from "../../components/CustomModal/Index";
import CustomNotification from "../../components/CustomNotification/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import institutionStyles from "../../styles/pages/InstitutionDash/index.style";

export default function InstitutionDetailsPage() {
  const classes = institutionStyles();
  const token = localStorage.getItem("token");
  const [isDisable, setDisable] = React.useState(false);
  const [state, setState] = React.useState({
    instName: "",
    instLogo: "",
  });
  const [inst, setInst] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isProgramModal, setProgramModal] = React.useState(false);
  const [programList, setProgramList] = React.useState([]);
  const [programState, setProgramState] = React.useState({
    programName: "",
    institutionName: "",
    year: null,
    isModify: false,
  });
  const [notify, setNotify] = React.useState(false);
  const getAllProgramList = () => {
    setProgramModal(false);
    setLoading(true);
    axios
      .post(endPoints.getProgramDetails, { token })
      .then((r) => {
        setLoading(false);
        setProgramList(r.data.results);
      })
      .catch((e) => setLoading(false));
  };
  React.useEffect(() => {
    axios.post(endPoints.getInstitutionDetails, { token }).then((r) => {
      setInst(r.data.results);
      r.data.results[0]
        ? setState({
            instLogo: r.data.results[0].insti_logo,
            instName: r.data.results[0].insti_name,
            ...r.data.results[0],
          })
        : setState({
            instName: "",
            instLogo: "",
          });
      !r.data.results[0] && setDisable(true);
    });
    getAllProgramList();
  }, []);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            <FaUniversity /> Institution Details
          </Typography>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomNotification
            open={!!notify}
            alertMessage={notify}
            severity={"success"}
            variant="filled"
            onClose={() => setNotify(false)}
          />
          <Grid container spacing={3}>
            <Grid item xs={8} className={classes.instituteInfoContainer}>
              <CustomInputField
                label="Institution Name"
                fullWidth
                value={state.instName}
                onChange={(e) => {
                  setState({
                    ...state,
                    instName: e.target.value,
                  });
                }}
              />
              <CustomInputField
                label="Institution Logo URL"
                fullWidth
                value={state.instLogo}
                onChange={(e) => {
                  setState({
                    ...state,
                    instLogo: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <Typography variant="h4">Last Modified </Typography>
                <Typography variant="body1">
                  {state.updated_at ? state.updated_at : "--"}
                </Typography>
                {inst.length > 0 ? (
                  <CustomButton
                    text="Update Institution Details"
                    variant="contained"
                    fullWidth
                    color="warning"
                    onClick={() => {
                      axios
                        .patch(endPoints.updateInstitutionDetails, {
                          ...state,
                          token,
                        })
                        .then((r) => {
                          setNotify("Institution Details Updated Successfully");
                          setDisable(false);
                        });
                    }}
                    className={classes.actionBtn}
                  />
                ) : (
                  <CustomButton
                    text="Save Institution Details"
                    disabled={!state.instLogo || !state.instName}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      axios
                        .post(endPoints.registerInstitutionDetails, {
                          ...state,
                          token,
                        })
                        .then((r) => {
                          setNotify("Institution Details Added Successfully");
                          setInst(r.data.results);
                          setDisable(false);
                        });
                    }}
                    className={classes.actionBtn}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.programContainer}>
            <CustomModal
              isOpen={isProgramModal}
              setOpen={setProgramModal}
              dialogTitle="Provide Program Details"
              mbContent={1}
              cancelBtnText="close"
              confirmBtnText={programState.isModify ? "Modify" : "Add"}
              actionCallback={(e) => {
                if (programState.isModify) {
                  axios
                    .patch(endPoints.updateProgramDetails, {
                      instId: state.insti_id,
                      ...programState,
                      token,
                    })
                    .then((r) => {
                      getAllProgramList();
                    });
                } else {
                  axios
                    .post(endPoints.registerProgramDetails, {
                      instId: state.insti_id,
                      ...programState,
                      token,
                    })
                    .then((r) => {
                      getAllProgramList();
                    });
                }
              }}
              dialogContent={
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CustomInputField
                      label="College/University Name"
                      fullWidth
                      value={programState.institutionName}
                      onChange={(e) => {
                        setProgramState({
                          ...programState,
                          institutionName: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInputField
                      label="Program Name"
                      fullWidth
                      value={programState.programName}
                      onChange={(e) => {
                        setProgramState({
                          ...programState,
                          programName: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Year"
                      views={["year"]}
                      fullWidth
                      dateFormat="yyyy"
                      value={programState.year}
                      changeCallBack={(e, v) => {
                        setProgramState({
                          ...programState,
                          year: v,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
            <Typography variant="h4" color="primary">
              College/Program Details
            </Typography>
            <CustomButton
              text="Add College/Program"
              variant="contained"
              icon={<FaPlus />}
              disabled={isDisable}
              onClick={() => {
                setProgramModal(true);
                setProgramState({
                  programName: "",
                  institutionName: "",
                  year: null,
                  isModify: false,
                });
              }}
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            />
            <CustomTable
              cols={[
                "SL. No.",
                "College/University Name",
                "Program",
                "Year",
                "Action",
              ]}
              loading={loading}
              rows={programList.map((pr, idx) => [
                idx + 1,
                pr.instituion_name,
                pr.program_name,
                new Date(pr.year).getFullYear(),
                <span>
                  <CustomIconButton
                    icon={<FaPen />}
                    onClick={(e) => {
                      setProgramModal(true);
                      setProgramState({
                        ...pr,
                        programName: pr.program_name,
                        institutionName: pr.instituion_name,
                        year: new Date(pr.year),
                        isModify: true,
                      });
                    }}
                    size="small"
                    color="warning"
                  />
                  <CustomIconButton
                    icon={<FaTrash />}
                    onClick={(e) => {
                      axios
                        .patch(endPoints.updateProgramDetails, {
                          token,
                          del_flag: 1,
                          program_details_id: pr.program_details_id,
                        })
                        .then((r) => getAllProgramList());
                    }}
                    size="small"
                    color="error"
                  />
                </span>,
              ])}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
